<template>
  <base-modal v-model="isOpen" :title="$t('invoice.table_settings_modal.title')">
    <div>
      <h2 class="text-subtitle-1 text-md-h6 font-weight-medium black--text">
        {{ $t('invoice.table_settings_modal.output_count') }}
      </h2>

      <v-radio-group v-model="limitModel" row>
        <v-radio v-for="count in outpuntCountList" :key="count" :label="`${count}`" :value="count"></v-radio>
      </v-radio-group>
    </div>
    <div>
      <h2 class="text-subtitle-1 text-md-h6 font-weight-medium black--text">
        {{ $t('invoice.table_settings_modal.columns') }}
      </h2>

      <v-list class="d-flex flex-wrap">
        <v-list-item
          v-for="item in filtredConfigs"
          :key="item.order"
          :input-value="item.active"
          ripple
          @click="toggleItem(item)"
        >
          <v-list-item-action class="me-2">
            <v-checkbox :input-value="item.active" :ripple="false" />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ $t(item.text) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>

    <!-- <v-btn v-if="media.isMobile" block class="elevation-0" color="primary" @click="applyConfig">
      {{ $t('button.apply') }}
    </v-btn> -->
  </base-modal>
</template>

<script>
import BaseModal from '@/components/BaseModal.vue';

import { createModelWrapper } from '@/utils/components';

export default {
  name: 'InvoiceTableSettings',
  components: { BaseModal },
  props: {
    limit: { type: Number, required: true },
    invoiceTableConfigs: { type: Array, required: true },
    accessToRooms: { type: Boolean, default: false },
  },
  data() {
    return {
      isOpen: false,
      outpuntCountList: [10, 25, 50, 100],
    };
  },

  computed: {
    limitModel: createModelWrapper('limit', 'update:limit'),
    configModel: createModelWrapper('invoiceTableConfigs', 'update:change-config'),
    filtredConfigs() {
      return this.accessToRooms
        ? this.invoiceTableConfigs
        : this.invoiceTableConfigs.filter(config => config.value !== 'roomName');
    },
  },

  methods: {
    open() {
      this.isOpen = true;
    },

    toggleItem(config) {
      this.configModel = this.configModel.map(configItem => {
        return configItem.value === config.value ? { ...configItem, active: !config.active } : configItem;
      });
    },
  },
};
</script>
