<template>
  <v-dialog
    v-model="modelWrapper"
    class="base-modal"
    :content-class="`base-modal__content elevation-0 ${contentClass}`"
    v-bind="$attrs"
  >
    <v-card content-class="base-modal__card">
      <v-card-title class="pb-8 px-4 pt-4 px-md-6 pt-md-6 align-start justify-space-between flex-nowrap">
        <h1 class="text-h6 text-md-h5 font-weight-bold">
          <slot name="title">
            {{ title }}
          </slot>
        </h1>

        <v-btn v-if="!hiddenCloseButton" :disabled="disabledCloseButton" icon color="primary" @click="close">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="px-4 px-md-6">
        <slot />
      </v-card-text>

      <v-card-actions>
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'BaseModal',

  inheritAttrs: false,

  model: {
    prop: 'isOpen',
    event: 'update:isOpen',
  },

  props: {
    isOpen: { type: Boolean, default: false },
    title: { type: String, default: '' },
    contentClass: { type: String, default: '' },
    disabledCloseButton: { type: Boolean, default: false },
    hiddenCloseButton: { type: Boolean, default: false },
  },

  computed: {
    modelWrapper: {
      get() {
        return this.isOpen;
      },

      set(value) {
        if (!value) {
          this.$emit('close');
        }

        this.$emit('update:isOpen', value);
      },
    },
  },

  methods: {
    close() {
      this.modelWrapper = false;
    },
  },
};
</script>

<style lang="scss">
.base-modal {
  &__content {
    max-width: 560px;
    margin: 8px !important;
  }
}
</style>
