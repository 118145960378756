export const INVOICE_TABLE_CONFIGS = 'invoiceTableConfigs';
export const INVOICE_ITEM_PAGE_COUNT = 'invoiceItemPageCount';

export const DEFAULT_HEADERS = {
  clientName: { active: true, order: 0, text: 'invoice.employee_invoice_for' },
  projectName: { active: true, order: 1, text: 'invoice.project', sortValue: 'project' },
  buildingName: { active: false, order: 1, text: 'invoice.building', sortable: false },
  unitName: { active: true, order: 2, text: 'invoice.unit', sortValue: 'unit', width: '250px' },
  roomName: { active: true, order: 3, text: 'invoice.room', sortValue: 'room', width: '250px' },
  serviceType: { active: true, order: 4, text: 'invoice.service_type' },
  invoiceNumber: { active: false, order: 5, text: 'invoice.invoice_number' },
  rule: { active: false, order: 6, text: 'invoice.is_rule', sortable: false },
  period: { active: false, order: 7, text: 'invoice.period' },
  invoiceDate: { active: true, order: 8, text: 'invoice.invoice_date' },
  totalAmount: { active: true, order: 9, text: 'invoice.total_amount', sortValue: 'amount' },
  paid: { active: false, order: 10, text: 'invoice.paid', sortable: false },
  debt: { active: false, order: 11, text: 'invoice.debt', sortable: false },
  dueDate: { active: true, order: 12, text: 'invoice.due_date' },
  days: { active: true, order: 13, text: 'invoice.delay' },
  status: { active: true, order: 14, text: 'invoice.status' },
  invoiceComment: { active: true, order: 15, text: 'invoice.comment', width: '200px', sortable: false },
  paymentType: { active: false, order: 16, text: 'invoice.payment_type' },
};
