<template>
  <v-row class="base-row-field-table">
    <v-col class="base-row-field-table__label" :class="{ 'py-1': removePadding }" cols="4">
      <slot name="label">
        {{ label }}
      </slot>
    </v-col>
    <v-col class="base-row-field-table__text" :class="{ 'py-1': removePadding }">
      <slot name="content">
        {{ text }}
      </slot>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'BaseRowFieldTable',
  props: {
    label: { type: String, default: '' },
    text: { type: String, required: true },
    removePadding: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss">
.base-row-field-table {
  &__label {
    font-weight: $--font-weight-normal;
    font-size: 12px;
    line-height: 20px;
    color: $--grey-color-0;
  }
  &__text {
    font-weight: $--font-weight-normal;
    font-size: 14px;
    line-height: 20px;
  }
}
</style>
