<template>
  <tr
    class="invoices-table-item"
    :class="{
      'invoices-table-item--unactive': isPaidRecurrenceInvoice,
      'blue--text': !seenInvoice,
    }"
  >
    <td
      v-for="header in headers"
      :key="header.value"
      :class="{
        'table-invoice-amount': header.value === 'totalAmount',
        'wrap-column': header.width,
      }"
      @click="header.value !== 'invoiceComment' && download()"
    >
      <template v-if="header.value === 'data-table-select'">
        <v-simple-checkbox color="primary" :value="isSelected" :ripple="false" @input="select" />
      </template>

      <div
        v-else-if="header.value === 'status'"
        :class="{ 'invoices-table-item__data-item--unactive-status': !isUnpaid }"
      >
        <span v-if="isPaid">{{ paymentDate }}</span>
        <span v-else-if="isPending">{{ $t('invoice.pending') }}</span>
        <div v-else>
          <template v-if="isEmployee">
            <span class="mr-2">{{ $t('invoice.unpaid') }}</span>
          </template>
          <template v-else>
            <v-btn
              v-if="!isRecurrence"
              exact
              color="secondary"
              class="primary--text"
              :to="invoicePaymentLink"
              @click.stop
            >
              {{ $t('button.pay') }}
            </v-btn>
          </template>
        </div>
      </div>

      <div v-else-if="header.value === 'paymentType'" class="d-flex align-center justify-space-between flex-nowrap">
        <div class="py-1">
          <span v-if="isAutoPaid">{{ $t('payment_type.application') }}</span>
          <span v-else-if="isPaid">{{ $t(paymentType) }}</span>

          <v-tooltip v-if="invoice.paymentComment" bottom max-width="200" content-class="payment-type-comment--tooltip">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" class="text-caption font-weight-light payment-type-comment" v-on="on">
                {{ invoice.paymentComment }}
              </div>
            </template>
            <span>
              {{ invoice.paymentComment }}
            </span>
          </v-tooltip>
        </div>

        <div class="d-flex align-center flex-nowrap">
          <v-menu v-if="!isAutoPaid && canUpdate" v-model="menu" left :offset-x="true" :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <icon-button
                :class="{ 'mr-11': (isPending && hasUnpaid) || (isPaid && hasUnpaid) }"
                :width="36"
                class="ml-3"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon color="primary">mdi-chevron-down</v-icon>
              </icon-button>
            </template>

            <v-list class="payment-type-list">
              <v-list-item-group v-model="paymentTypeSelect" color="primary">
                <v-list-item v-if="isPaid">
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('payment_type.unpaid') }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-for="paymentTypeItem in paymentTypes"
                  :key="paymentTypeItem.name"
                  :value="paymentTypeItem.value"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ $t(paymentTypeItem.name) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
              <v-list-item>
                <v-text-field
                  v-model="paymentTypeComment"
                  maxlength="64"
                  counter="64"
                  :placeholder="$t('label.comment')"
                  class="mb-3"
                ></v-text-field>
              </v-list-item>
              <div class="text-center mb-2">
                <v-btn color="primary" @click="savePaymentType">{{ $t('button.save') }}</v-btn>
              </div>
            </v-list>
          </v-menu>

          <icon-button
            v-if="!isPaid && !isPending && canUpdate"
            exact
            :width="36"
            class="ml-2"
            @click.stop
            @click="deleteInvoice"
          >
            <v-icon color="primary">mdi-delete</v-icon>
          </icon-button>
        </div>
      </div>

      <template v-else-if="header.value === 'amount'"> {{ invoice.amount }} {{ invoice.currency.symbol }} </template>

      <template v-else-if="header.value === 'serviceType'">
        {{ serviceType }}
      </template>

      <template v-else-if="header.value === 'totalAmount'">
        <div class="d-flex align-center justify-space-between flex-nowrap">
          {{ totalSum }} {{ invoice.currency && invoice.currency.symbol }}

          <div v-if="isRecurrence && canUpdate" class="d-flex justify-end ml-7">
            <icon-button exact class="mr-2" :width="36" :to="invoicesEditLink" @click.stop>
              <v-icon color="primary">mdi-pencil</v-icon>
            </icon-button>

            <icon-button exact :width="36" @click.stop @click="deleteInvoice">
              <v-icon color="primary">mdi-delete</v-icon>
            </icon-button>
          </div>
        </div>
      </template>

      <template v-else-if="header.value === 'invoiceDate'">
        {{ invoiceDate }}
      </template>

      <template v-else-if="header.value === 'period'">
        {{ invoicePeriod }}
      </template>

      <template v-else-if="header.value === 'dueDate'">
        {{ invoiceDueDate }}
      </template>

      <template v-else-if="header.value === 'days'">
        <span :class="{ 'is-overdue': isOverdue }">{{ invoiceDelayedPayment }}</span>
      </template>

      <template v-else-if="header.value === 'invoiceDateFirst'">
        {{ invoiceDateFirst }}
      </template>

      <template v-else-if="header.value === 'invoiceDateLast'">
        {{ invoiceDateLast }}
      </template>

      <template v-else-if="header.value === 'clientName'">
        {{ invoice[header.value] }}
      </template>

      <template v-else-if="header.value === 'rule'">
        {{ invoice[header.value] ? $t('yes') : $t('no') }}
      </template>

      <template v-else-if="header.value === 'paid'">
        {{ paidSum }} {{ invoice.currency && invoice.currency.symbol }}
      </template>

      <template v-else-if="header.value === 'debt'">
        {{ debtSum }} {{ invoice.currency && invoice.currency.symbol }}
      </template>

      <template v-else-if="header.value === 'invoiceComment'">
        <table-item-comment :value="invoice[header.value]" :width="header.width" @input="updateComment" />
      </template>

      <template v-else>
        {{ invoice[header.value] }}
      </template>
    </td>

    <download-overlay
      :is-loading="invoice.isLoading"
      :file-name="invoice.fileName"
      :progress="invoice.progress"
      :is-error="invoice.isError"
      @repeat="download"
      @close-overlay="closeOverlay"
    />
  </tr>
</template>

<script>
// Constants
import { INVOICES_PAYMENT, INVOICES_RECURRENCE_EDIT } from '@/constants/routes';
import * as entityStatuses from '@/constants/entityStatuses';

// Utils
import { translateDate, translateInvoiceDate } from '@/utils/dateFormatting';

// Components
import DownloadOverlay from '@/components/Invoices/DownloadOverlay.vue';
import IconButton from '@/components/IconButton.vue';
import TableItemComment from './TableItemComment.vue';

export default {
  name: 'InvoicesTableItem',

  components: { TableItemComment, DownloadOverlay, IconButton },

  props: {
    headers: { type: Array, required: true },
    isSelected: { type: Boolean, required: true },
    invoice: { type: Object, required: true },
    paymentTypes: { type: Array, required: true },
    isEmployee: { type: Boolean, required: true },
    isRecurrence: { type: Boolean, required: true },
    canUpdate: { type: Boolean, default: false },
    hasUnpaid: { type: Boolean, default: false },
    codeLanguage: { type: String, default: 'en' },
  },

  data: () => ({
    menu: false,
    paymentTypeSelect: '',
    paymentTypeComment: '',
  }),

  computed: {
    totalSum() {
      try {
        return parseFloat(this.invoice.totalAmount).toLocaleString(this.codeLanguage);
      } catch {
        return parseFloat(this.invoice.totalAmount).toLocaleString('en');
      }
    },
    paidSum() {
      try {
        return parseFloat(this.invoice.paid).toLocaleString(this.codeLanguage);
      } catch {
        return parseFloat(this.invoice.paid).toLocaleString('en');
      }
    },
    debtSum() {
      try {
        return parseFloat(this.invoice.debt).toLocaleString(this.codeLanguage);
      } catch {
        return parseFloat(this.invoice.debt).toLocaleString('en');
      }
    },
    paymentDate() {
      return translateDate(this.invoice.paymentDate);
    },

    paymentType() {
      let paymentType = '';

      if (this.isPaid && !this.isAutoPaid) {
        for (let i = 0; i < this.paymentTypes.length; i += 1) {
          if (this.paymentTypes[i].value === this.invoice.paymentTypeId) {
            paymentType = this.paymentTypes[i].name;
            break;
          }
        }
      }

      return paymentType;
    },

    invoiceDateLast() {
      return translateDate(this.invoice.invoiceDateLast);
    },

    invoiceDateFirst() {
      return translateDate(this.invoice?.invoiceDateFirst);
    },

    invoiceDate() {
      return translateDate(this.invoice.invoiceDate);
    },

    invoicePeriod() {
      return translateInvoiceDate(
        this.invoice.isOneTime,
        this.invoice.period,
        this.invoice.periodType !== 'day' && this.invoice.periodEnd
      );
    },

    invoiceDueDate() {
      return translateDate(this.invoice.dueDate);
    },

    invoiceDelayedPayment() {
      const daysTranslate = this.getDaysTranslateKey(Math.abs(this.invoice.days));

      if (this.invoice.days < 0) {
        return `${this.$t('invoice.payment_in')} ${Math.abs(this.invoice.days)} ${this.$t(daysTranslate)}`;
      }

      if (this.invoice.days > 0) {
        return `${this.$t('invoice.overdue_by')} ${Math.abs(this.invoice.days)} ${this.$t(daysTranslate)}`;
      }

      if (this.invoice.days === 0) {
        return this.isPaid ? this.$t('invoice.is_paid') : this.$t('invoice.payment_today');
      }

      return '-';
    },

    isOverdue() {
      return this.invoice.days > 0;
    },

    isPaidRecurrenceInvoice() {
      return this.isRecurrence && this.invoice.status !== 'unpaid';
    },

    seenInvoice() {
      if (this.isEmployee) {
        return true;
      }

      return this.invoice.isSeenByClient;
    },

    serviceType() {
      return this.$t(this.invoice.serviceType?.name) || this.invoice.service;
    },

    isPaid() {
      return this.invoice.status === entityStatuses.PAID;
    },

    isAutoPaid() {
      return this.invoice.status === entityStatuses.PAID && this.invoice.paymentTypeId === null;
    },

    isPending() {
      return this.invoice.status === entityStatuses.PENDING;
    },

    isUnpaid() {
      return this.invoice.status === entityStatuses.UNPAID;
    },

    invoicePaymentLink() {
      return { name: INVOICES_PAYMENT, params: { id: this.invoice.id } };
    },

    invoicesEditLink() {
      return { name: INVOICES_RECURRENCE_EDIT, params: { id: this.invoice.id } };
    },
  },

  watch: {
    invoice({ paymentTypeId, paymentComment }) {
      this.paymentTypeSelect = paymentTypeId;
      this.paymentTypeComment = paymentComment;
    },
  },

  mounted() {
    this.paymentTypeSelect = this.invoice.paymentTypeId;
    this.paymentTypeComment = this.invoice.paymentComment;
  },

  methods: {
    select(...args) {
      this.$emit('select', ...args);
    },

    download() {
      this.$emit('download', this.invoice);
    },

    closeOverlay() {
      this.$emit('close-overlay', this.invoice);
    },

    deleteInvoice() {
      this.$emit('delete', this.invoice);
    },

    savePaymentType() {
      this.menu = false;
      this.$emit('save-payment-type', this.paymentTypeSelect || null, this.paymentTypeComment, this.invoice.id);
    },

    getDaysTranslateKey(number) {
      if (number === 1) {
        return 'one_day';
      }
      const cases = [2, 0, 1, 1, 1, 2];
      const index = number % 100 > 4 && number % 100 < 20 ? 2 : cases[Math.min(number % 10, 5)];
      return ['x1_days', 'x2_days', 'x5_days'][index];
    },

    updateComment(comment) {
      this.$emit('update-comment', { comment, invoiceId: this.invoice.id });
    },
  },
};
</script>

<style lang="scss">
.invoices-table-item {
  position: relative;

  &--unactive {
    color: rgba($--black-color-0, 0.5) !important;
  }

  &__data-item {
    &--unactive-status {
      color: rgba($--green-color-0, 0.9) !important;
    }
  }

  .is-overdue {
    color: red !important;
  }
}

.table-invoice-amount {
  white-space: nowrap;
}

.payment-type-list {
  .v-input--selection-controls__input {
    position: absolute !important;
    opacity: 0 !important;
    overflow: hidden !important;
    width: 0 !important;
    height: 0 !important;
    pointer-events: none !important;
  }

  .v-input--selection-controls .v-input__slot,
  .v-input--selection-controls .v-radio,
  .v-input {
    width: 100%;
  }

  .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
    border-color: rgba(0, 0, 196, 0.15) !important;
  }
}

.payment-type-active {
  background: $--blue-color-40 !important;

  label {
    color: $--blue-color-10 !important;
  }
}

.payment-type-comment {
  line-height: 1em !important;
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &--tooltip {
    word-break: break-word;
    padding: 5px 10px !important;
    line-height: 1em !important;
  }
}
</style>
