<template>
  <div class="comment d-lg-flex align-center">
    <v-tooltip v-if="valueModel && !media.isMobile" bottom max-width="200" content-class="comment--tooltip">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" class="comment__content text-caption font-weight-light" v-on="on">
          {{ valueModel }}
        </span>
      </template>
      <span>
        {{ valueModel }}
      </span>
    </v-tooltip>
    <v-menu v-model="menu" left :close-on-content-click="false" :nudge-width="300" offset-x>
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <span v-if="media.isMobile" class="text-caption font-weight-light">{{ valueModel }}</span>
          <v-icon color="primary" class="ms-2 edit-comment" size="small">
            mdi-pencil
          </v-icon>
        </div>
      </template>

      <v-card>
        <v-card-text>
          <v-textarea
            v-model="editedValue"
            :label="$t('invoice.edit_comment')"
            autofocus
            counter
            no-resize
            maxlength="255"
            :rules="[v => v.length <= 255]"
            @keyup.enter.prevent="saveEdit"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeMenu">{{ $t('button.cancel') }}</v-btn>
          <v-btn color="primary" text @click="saveEdit">{{ $t('button.save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { createModelWrapper } from '@/utils/components';

export default {
  name: 'TableItemComment',
  props: {
    value: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: null,
    },
  },
  inject: ['media'],
  data() {
    return {
      menu: false,
      editedValue: '',
    };
  },
  computed: {
    valueModel: createModelWrapper('value', 'input'),
  },
  watch: {
    menu(val) {
      if (val) {
        this.editedValue = this.valueModel;
      }
    },
  },
  methods: {
    closeMenu() {
      this.menu = false;
    },
    saveEdit() {
      console.log('saveEdit');
      this.valueModel = this.editedValue;
      this.menu = false;
    },
  },
};
</script>

<style lang="scss">
.comment {
  &__content {
    line-height: 1em !important;
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--tooltip {
    word-break: break-word;
    padding: 5px 10px !important;
    line-height: 1em !important;
  }

  .edit-comment {
    @media screen and (min-width: 1280px) {
      opacity: 0;
    }
    transition: opacity 0.05s;
  }

  &:hover .edit-comment {
    opacity: 1;
  }
}
</style>
