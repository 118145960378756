<template>
  <div
    ref="wrapper"
    class="invoices-table"
    :class="{
      'show-left-shadow': showLeftShadow,
      'show-right-shadow': showRightShadow,
      'show-top-shadow': topScrollable && showTopShadow,
      'show-bottom-shadow': topScrollable && showBottomShadow,
    }"
  >
    <base-table
      ref="table"
      v-model="valueModel"
      hide-default-footer
      fixed-header
      :height="topScrollable ? 650 : null"
      :show-select="isEmployee"
      :items="invoices"
      :loading="loading"
      :options.sync="optionsModel"
      :headers="headers"
      :server-items-length="serverItemsLength"
      @scroll="handleScroll"
    >
      <template v-slot:[`header.data-table-select`]="{ props, on }">
        <v-menu v-model="dialogIsOpen" :close-on-content-click="false" offset-y>
          <template #activator="{ on: modalActivator }">
            <v-simple-checkbox
              v-bind="props"
              :ripple="false"
              color="primary"
              @click="selectAll(props, modalActivator)"
              v-on="on"
            />
          </template>
          <v-card max-width="352">
            <v-card-text class="text-subtitle-2">
              {{ $t('invoicevt.select_info') }}
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn text class="font-weight-bold" color="primary" @click="selectAllInPage"
                >{{ $t('button.all_in_page') }} ({{ pageInvoicesCount }})</v-btn
              >
              <v-btn text class="font-weight-bold" color="primary" @click="selectGlobal"
                >{{ $t('button.all') }} ({{ invoicesCount }})</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-menu>
      </template>

      <template v-slot:item="{ headers: itemHeaders, item, select, isSelected }">
        <table-item
          :is-employee="isEmployee"
          :is-recurrence="isRecurrence"
          :headers="itemHeaders"
          :is-selected="isSelected"
          :invoice="item"
          :payment-types="paymentTypes"
          :can-update="canUpdate"
          :has-unpaid="hasUnpaid"
          :code-language="codeLanguage"
          @select="select"
          @download="downloadInvoice"
          @close-overlay="closeOverlay"
          @delete="deleteInvoice"
          @save-payment-type="savePaymentType"
          @update-comment="updateComment"
        />
      </template>
    </base-table>
  </div>
</template>

<script>
// Utils
import { createModelWrapper } from '@/utils/components';

// Components
import BaseTable from '@/components/BaseTable.vue';
import TableItem from './TableItem.vue';

export default {
  name: 'InvoicesTable',

  components: { TableItem, BaseTable },

  inject: ['media'],

  props: {
    value: { type: Array, required: true },
    invoices: { type: Array, required: true },
    paymentTypes: { type: Array, required: true },
    options: { type: Object, required: true },
    headers: { type: Array, required: true },
    pageCount: { type: Number, required: true },
    invoicesCount: { type: Number, required: true },
    pageInvoicesCount: { type: Number, required: true },
    isEmployee: { type: Boolean, required: true },
    isRecurrence: { type: Boolean, required: true },
    loading: { type: Boolean, default: false },
    serverItemsLength: { type: [Boolean, Number], default: false },
    canUpdate: { type: Boolean, default: false },
    status: { type: String, required: true },
    codeLanguage: { type: String, default: 'en' },
    topScrollable: { type: Boolean, default: false },
  },

  data() {
    return {
      dialogIsOpen: false,
      showLeftShadow: false,
      showRightShadow: false,
      showTopShadow: false,
      showBottomShadow: false,
    };
  },

  computed: {
    valueModel: createModelWrapper('value', 'input'),
    optionsModel: createModelWrapper('options', 'update:options'),
    hasUnpaid() {
      for (let i = 0; i < this.invoices.length; i += 1) {
        if (this.invoices[i].status === 'unpaid') return true;
      }
      return false;
    },
  },

  watch: {
    status() {
      this.showLeftShadow = false;
      this.showRightShadow = false;
      this.showTopShadow = false;
      this.showBottomShadow = false;
      this.$nextTick(() => {
        const tableWrapper = this.$refs.table?.$el.querySelector('.v-data-table__wrapper');
        if (!tableWrapper) return;
        tableWrapper.scrollLeft = 0;
        this.handleScroll({ target: tableWrapper });
      });
    },
  },

  mounted() {
    this.$nextTick(() => {
      const tableWrapper = this.$refs.table?.$el.querySelector('.v-data-table__wrapper');
      if (!tableWrapper) return;
      this.handleScroll({ target: tableWrapper });
      tableWrapper.addEventListener('scroll', this.handleScroll);
    });
  },
  beforeDestroy() {
    const tableWrapper = this.$refs.table?.$el.querySelector('.v-data-table__wrapper');
    if (!tableWrapper) return;
    tableWrapper.removeEventListener('scroll', this.handleScroll);
  },

  methods: {
    selectAll(props) {
      if (this.media.isMobile) {
        this.valueModel = this.invoices;

        if (this.pageCount > 1) {
          this.dialogIsOpen = true;
        }

        return;
      }

      if (!props?.value && this.pageCount > 1) {
        this.dialogIsOpen = true;
      }
    },

    selectAllInPage() {
      this.$emit('global-select', false);
      this.closeDialog();
    },

    selectGlobal() {
      this.$emit('global-select', true);
      this.closeDialog();
    },

    closeDialog() {
      this.dialogIsOpen = false;
    },

    downloadInvoice(invoice) {
      this.$emit('download-invoice', invoice);
    },

    closeOverlay(invoice) {
      this.$emit('close-overlay', invoice);
    },

    deleteInvoice(invoice) {
      this.$emit('delete-invoice', invoice);
    },

    savePaymentType(invoice, paymentTypeComment, paymentType) {
      this.$emit('save-payment-type', invoice, paymentTypeComment, paymentType);
    },

    updateComment(comment) {
      this.$emit('update-comment', comment);
    },

    handleScroll(evt) {
      const { target } = evt;
      this.showLeftShadow = target.scrollLeft > 0;
      this.showRightShadow = target.scrollLeft < target.scrollWidth - target.clientWidth;
      this.showTopShadow = target.scrollTop > 0;
      this.showBottomShadow = target.scrollTop < target.scrollHeight - target.clientHeight;
    },
  },
};
</script>

<style lang="scss">
.invoices-table {
  position: relative;
  overflow: hidden;

  table {
    th,
    td {
      white-space: nowrap;

      &.wrap-column {
        white-space: wrap;
      }
    }
  }

  .v-data-table {
    max-width: 100%;
    overflow: auto;
  }

  &::before,
  &::after,
  .v-data-table::before,
  .v-data-table::after {
    content: '';
    position: absolute;
    z-index: 1;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &::before,
  &::after {
    left: 0;
    right: 0;
    height: 20px;
  }

  .v-data-table::before,
  .v-data-table::after {
    top: 0;
    bottom: 0;
    width: 20px;
  }

  &::before {
    top: 48px;
    background: radial-gradient(farthest-side at 50% 0%, rgba(0, 0, 0, 0.2), transparent);
  }

  &::after {
    bottom: 0;
    background: radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), transparent);
  }

  .v-data-table::before {
    left: 0;
    background: radial-gradient(farthest-side at 0% 50%, rgba(0, 0, 0, 0.2), transparent);
  }

  .v-data-table::after {
    right: 0;
    background: radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, 0.2), transparent);
  }

  &.show-top-shadow::before {
    opacity: 1;
  }
  &.show-bottom-shadow::after {
    opacity: 1;
  }
  &.show-left-shadow .v-data-table::before {
    opacity: 1;
  }
  &.show-right-shadow .v-data-table::after {
    opacity: 1;
  }
}
</style>
