import * as serializers from '@/constants/serializer';
import * as comparators from '@/constants/comparators';
import { ENUM } from '@/constants/viewTypes';
import * as schemaItemTypes from './schemaItemTypes';
import * as onUpdateEffects from './onUpdateEffects';
import * as dictionaryTypes from './dictionaryTypes';

const dependentProperties = {
  BUILDINGS: 'buildings',
  PROJECTS: 'projects',
  UNITS: 'units',
  ROOMS: 'rooms',
  CLIENTS: 'clients',
  PAYMENT_TYPE: 'paymentTypes',
  MANY_SERVICE_TYPES: 'serviceTypes',
  PAYMENT_DEADLINE: 'paymentDeadline',
  PAYMENT_DEADLINE_PERIOD: 'paymentDeadlinePeriod',
  STATUS: 'status',
};

export const schema = [
  {
    type: schemaItemTypes.MANY_PROJECTS,
    label: 'label.projects',
    prop: dependentProperties.PROJECTS,
    payload: [
      {
        param: 'buildings',
        from: [dependentProperties.BUILDINGS],
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
        onlyEffect: true,
      },
    ],
    onUpdate: [
      {
        type: onUpdateEffects.RESET,
        to: dependentProperties.BUILDINGS,
      },
    ],
  },
  {
    type: schemaItemTypes.MANY_BUILDINGS,
    label: 'label.buildings',
    prop: dependentProperties.BUILDINGS,
    payload: [
      {
        param: 'projects',
        from: [dependentProperties.PROJECTS],
        cache: true,
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
      },
      {
        param: 'units',
        from: [dependentProperties.UNITS],
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
        onlyEffect: true,
      },
    ],
    onUpdate: [
      {
        type: onUpdateEffects.AUTOCOMPLETE_MANY,
        to: dependentProperties.PROJECTS,
      },
      {
        type: onUpdateEffects.RESET,
        to: dependentProperties.UNITS,
      },
    ],
  },
  {
    type: schemaItemTypes.MANY_UNITS,
    label: 'label.units',
    prop: dependentProperties.UNITS,
    payload: [
      {
        param: 'projects',
        from: [dependentProperties.PROJECTS],
        cache: true,
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
      },
      {
        param: 'buildings',
        from: [dependentProperties.BUILDINGS],
        cache: true,
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
      },
      {
        param: 'rooms',
        from: [dependentProperties.ROOMS],
        onlyEffect: true,
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
      },
      {
        param: 'clients',
        from: [dependentProperties.CLIENTS],
        onlyEffect: true,
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
      },
    ],
    onUpdate: [
      {
        type: onUpdateEffects.AUTOCOMPLETE_MANY,
        to: dependentProperties.BUILDINGS,
      },
      {
        type: onUpdateEffects.RESET,
        to: dependentProperties.CLIENTS,
      },
    ],
  },
  {
    type: schemaItemTypes.MANY_ROOMS,
    label: 'label.rooms',
    prop: dependentProperties.ROOMS,
    access: ['rooms'],
    payload: [
      {
        param: 'projects',
        from: [dependentProperties.PROJECTS],
        cache: true,
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
      },
      {
        param: 'buildings',
        from: [dependentProperties.BUILDINGS],
        cache: true,
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
      },
      {
        param: 'units',
        from: [dependentProperties.UNITS],
        cache: true,
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
      },
    ],
    onUpdate: [
      {
        type: onUpdateEffects.AUTOCOMPLETE_MANY,
        to: dependentProperties.UNITS,
      },
      {
        type: onUpdateEffects.RESET,
        to: dependentProperties.CLIENTS,
      },
    ],
  },
  {
    type: schemaItemTypes.MANY_CLIENTS,
    label: 'label.clients',
    prop: dependentProperties.CLIENTS,
    payload: [
      {
        param: 'projects',
        from: [dependentProperties.PROJECTS],
        cache: true,
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
      },
      {
        param: 'buildings',
        from: [dependentProperties.BUILDINGS],
        cache: true,
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
      },
      {
        param: 'units',
        from: [dependentProperties.UNITS],
        cache: true,
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
      },
    ],
    onUpdate: [
      {
        type: onUpdateEffects.AUTOCOMPLETE_MANY,
        to: dependentProperties.UNITS,
      },
    ],
  },
  {
    type: schemaItemTypes.ENUM,
    viewType: ENUM.select,
    label: 'label.payment_deadline',
    clearable: true,
    prop: dependentProperties.PAYMENT_DEADLINE,
    dictionary: dictionaryTypes.PATMENT_DEADLINE_TYPES,
  },
  {
    type: schemaItemTypes.DATE,
    label: 'invoice.payment_deadline_range',
    prop: dependentProperties.PAYMENT_DEADLINE_PERIOD,
    clearable: true,
    multiple: true,
    displayConditions: {
      variables: [
        {
          from: [dependentProperties.PAYMENT_DEADLINE, 'value'],
          comparableValues: ['custom'],
          comparator: comparators.STRICT_COMPARE,
        },
      ],
      expression: `value`,
    },
  },
];

export const invoiceFilterNewSchema = [
  {
    type: schemaItemTypes.MANY_PROJECTS,
    label: 'label.projects',
    prop: dependentProperties.PROJECTS,
    payload: [
      {
        param: 'buildings',
        from: [dependentProperties.BUILDINGS],
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
        onlyEffect: true,
      },
    ],
    onUpdate: [
      {
        type: onUpdateEffects.RESET,
        to: dependentProperties.BUILDINGS,
      },
    ],
  },
  {
    type: schemaItemTypes.MANY_BUILDINGS,
    label: 'label.buildings',
    prop: dependentProperties.BUILDINGS,
    payload: [
      {
        param: 'projects',
        from: [dependentProperties.PROJECTS],
        cache: true,
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
      },
      {
        param: 'units',
        from: [dependentProperties.UNITS],
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
        onlyEffect: true,
      },
    ],
    onUpdate: [
      {
        type: onUpdateEffects.AUTOCOMPLETE_MANY,
        to: dependentProperties.PROJECTS,
      },
      {
        type: onUpdateEffects.RESET,
        to: dependentProperties.UNITS,
      },
    ],
  },
  {
    type: schemaItemTypes.MANY_UNITS,
    label: 'label.units',
    prop: dependentProperties.UNITS,
    payload: [
      {
        param: 'projects',
        from: [dependentProperties.PROJECTS],
        cache: true,
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
      },
      {
        param: 'buildings',
        from: [dependentProperties.BUILDINGS],
        cache: true,
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
      },
      {
        param: 'rooms',
        from: [dependentProperties.ROOMS],
        onlyEffect: true,
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
      },
      {
        param: 'clients',
        from: [dependentProperties.CLIENTS],
        onlyEffect: true,
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
      },
    ],
    onUpdate: [
      {
        type: onUpdateEffects.AUTOCOMPLETE_MANY,
        to: dependentProperties.BUILDINGS,
      },
      {
        type: onUpdateEffects.RESET,
        to: dependentProperties.CLIENTS,
      },
    ],
  },
  {
    type: schemaItemTypes.MANY_ROOMS,
    label: 'label.rooms',
    prop: dependentProperties.ROOMS,
    access: ['rooms'],
    payload: [
      {
        param: 'projects',
        from: [dependentProperties.PROJECTS],
        cache: true,
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
      },
      {
        param: 'buildings',
        from: [dependentProperties.BUILDINGS],
        cache: true,
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
      },
      {
        param: 'units',
        from: [dependentProperties.UNITS],
        cache: true,
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
      },
    ],
    onUpdate: [
      {
        type: onUpdateEffects.AUTOCOMPLETE_MANY,
        to: dependentProperties.UNITS,
      },
      {
        type: onUpdateEffects.RESET,
        to: dependentProperties.CLIENTS,
      },
    ],
  },
  {
    type: schemaItemTypes.MANY_CLIENTS,
    label: 'label.clients',
    prop: dependentProperties.CLIENTS,
    payload: [
      {
        param: 'projects',
        from: [dependentProperties.PROJECTS],
        cache: true,
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
      },
      {
        param: 'buildings',
        from: [dependentProperties.BUILDINGS],
        cache: true,
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
      },
      {
        param: 'units',
        from: [dependentProperties.UNITS],
        cache: true,
        serializer: serializers.EXTRACT_PARAMS_FROM_MULTI_SELECT_OBJECT,
      },
    ],
    onUpdate: [
      {
        type: onUpdateEffects.AUTOCOMPLETE_MANY,
        to: dependentProperties.UNITS,
      },
    ],
  },
  {
    type: schemaItemTypes.ENUM,
    viewType: ENUM.select,
    label: 'label.payment_deadline',
    clearable: true,
    prop: dependentProperties.PAYMENT_DEADLINE,
    dictionary: dictionaryTypes.PATMENT_DEADLINE_TYPES,
  },
  {
    type: schemaItemTypes.DATE,
    label: 'invoice.payment_deadline_range',
    prop: dependentProperties.PAYMENT_DEADLINE_PERIOD,
    clearable: true,
    multiple: true,
    displayConditions: {
      variables: [
        {
          from: [dependentProperties.PAYMENT_DEADLINE, 'value'],
          comparableValues: ['custom'],
          comparator: comparators.STRICT_COMPARE,
        },
      ],
      expression: `value`,
    },
  },
  {
    type: schemaItemTypes.ENUM,
    viewType: ENUM.select,
    label: 'label.invoice_status',
    prop: dependentProperties.STATUS,
    dictionary: dictionaryTypes.INVOICE_STATUS,
    defaultValue: { name: 'invoice.all_status', value: 'all' },
  },
];

export const invoiceFilterModalNewSchema = [
  {
    type: schemaItemTypes.DATE,
    label: 'label.payment_period',
    prop: 'paymentPeriod',
    clearable: true,
    multiple: true,
  },
  {
    type: schemaItemTypes.DATE,
    label: 'label.invoice_period',
    prop: 'invoicePeriod',
    clearable: true,
    multiple: true,
  },
  {
    type: schemaItemTypes.MANY_SERVICE_TYPES,
    label: 'label.services_types',
    prop: dependentProperties.MANY_SERVICE_TYPES,
  },
  {
    type: schemaItemTypes.MANY_PAYMENT_TYPE,
    label: 'label.payment_types',
    prop: dependentProperties.PAYMENT_TYPE,
  },
];

export const fullNewSchema = [...invoiceFilterNewSchema, ...invoiceFilterModalNewSchema];

export { schema as default };
