<template>
  <div class="invoices-filters d-flex align-center">
    <form-builder
      ref="formBuilder"
      v-model="valueModel"
      enable-cache
      remove-label-suffix
      class="invoices-filters__row me-4"
      :schema="isAllStatus ? $options.invoiceFilterNewSchema : $options.schema"
      :initial-data="value"
    >
    </form-builder>
    <v-btn elevation="0" color="#EEEEEE" class="ms-auto mb-2" @click="isOpenMoreFilters = true">
      <v-icon class="me-2">mdi-chevron-left</v-icon>
      {{ $t('button.show_more_filters') }}
    </v-btn>

    <base-modal
      v-model="isOpenMoreFilters"
      :title="$t('invoice.filters')"
      transition="dialog-bottom-transition"
      max-width="400"
      content-class="filter-dialog"
      scrollable
    >
      <form-builder
        ref="formBuilder"
        v-model="valueModel"
        enable-cache
        remove-label-suffix
        class="flex-grow-1"
        :schema="$options.invoiceFilterModalNewSchema"
        :initial-data="value"
      />
    </base-modal>
  </div>
</template>

<script>
// Components
import BaseModal from '@/components/BaseModal.vue';
import FormBuilder from '@/components/schema/FormBuilder.vue';

// Models
import { invoiceFilterNewSchema, invoiceFilterModalNewSchema, schema } from '@/schemas/invoiceFilter.schema';

// Utils
import { createModelWrapper } from '@/utils/components';

export default {
  name: 'InvoicesFilters',

  components: { FormBuilder, BaseModal },

  props: {
    value: { type: Object, default: () => ({}) },
    status: { type: String, required: true },
  },

  data() {
    return {
      isOpenMoreFilters: false,
    };
  },

  computed: {
    valueModel: createModelWrapper('value', 'input'),
    isAllStatus() {
      return this.status === 'all';
    },
  },

  methods: {
    reset() {
      this.$refs.formBuilder.reset();
    },
  },

  invoiceFilterNewSchema,
  invoiceFilterModalNewSchema,
  schema,
};
</script>

<style lang="scss">
.invoices-filters {
  &__row .v-form {
    display: flex;
    column-gap: 8px;

    .date-input {
      flex: 0 0 auto;
      width: 265px;
    }
  }

  .show-filters {
    &__icon {
      transition: transform 0.2s;

      &.active {
        transform: rotate(180deg);
      }
    }
  }
}

.filter-dialog {
  margin: 0 !important;
  margin-left: auto !important;
  max-height: 100% !important;
  height: 100%;

  .close {
    position: absolute;
    top: 32px;
    right: 32px;

    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid #ccc;
    background: #fff;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .v-card {
    height: 100%;
  }
}
</style>
