<template>
  <base-card class="invoice-metric__card" :loading="loading">
    <template v-slot:header>
      <h6 class="invoice-metric__card-title">
        {{ label }}
      </h6>
    </template>
    <p v-if="amount" class="invoice-metric__card-text">
      {{ amountLocal }} <span>{{ currency.symbol }}</span>
    </p>
    <span>
      <span class="caption me-1">{{ $t('invoices.invoices_count') }}:</span>
      <b>{{ invoicesCount }}</b>
    </span>
  </base-card>
</template>

<script>
import BaseCard from '@/components/BaseCard.vue';

export default {
  name: 'InvoiceMetricCard',
  components: { BaseCard },
  props: {
    loading: { type: Boolean, default: false },
    codeLanguage: { type: String, default: 'en' },
    label: { type: String, default: '' },
    amount: { type: Number, default: null },
    currency: { type: Object, default: () => ({}) },
    invoicesCount: { type: Number, default: null },
  },
  data() {
    return {};
  },
  computed: {
    amountLocal() {
      try {
        return this.amount.toLocaleString(this.codeLanguage);
      } catch {
        return this.amount.toLocaleString('en');
      }
    },
  },
};
</script>

<style lang="scss">
.invoice-metric {
  &__title {
    font-weight: $--font-weight-bold;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px;
  }
  &__card {
    height: 100%;
    min-height: 104px;
    @media (max-width: map.get($--screens, 'sm')) {
      min-height: 76px;
    }
  }
  &__card-title {
    font-weight: $--font-weight-normal;
    font-size: 16px;
    line-height: 24px;
    opacity: 0.5;
    @media (max-width: map.get($--screens, 'sm')) {
      font-size: 12px;
      line-height: 20px;
    }
  }
  &__card-text {
    font-weight: $--font-weight-medium;
    font-size: 34px;
    line-height: 44px;
    font-weight: 500;
    @media (max-width: map.get($--screens, 'sm')) {
      font-size: 24px;
      line-height: 34px;
    }
    @media (max-width: map.get($--screens, 'xs')) {
      font-size: 18px;
      line-height: 26px;
    }

    sup {
      top: 0;
      vertical-align: super;
      font-size: 18px;
      font-weight: 600;

      @media (max-width: map.get($--screens, 'sm')) {
        font-size: 14px;
      }
      @media (max-width: map.get($--screens, 'xs')) {
        font-size: 10px;
      }
    }
  }
}
</style>
