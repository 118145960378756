<template>
  <div class="d-flex">
    <template v-if="canUpdateInvoices && !media.isMobile">
      <v-menu offset-y left>
        <template #activator="{ attrs, on }">
          <v-btn large class="mr-3 elevation-0" color="primary" v-bind="attrs" v-on="on">
            {{ $t('button.add') }}
            <v-icon right>
              mdi-plus
            </v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="issueInvoice">
            {{ $t('button.issue_invoice') }}
          </v-list-item>

          <v-list-item @click="issueRecurrenceInvoice">
            {{ $t('button.issue_reccurence_invoice') }}
          </v-list-item>
        </v-list>
      </v-menu>
    </template>

    <v-menu offset-y left min-width="250">
      <template #activator="{ on, attrs }">
        <icon-button v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-horizontal</v-icon>
        </icon-button>
      </template>

      <v-list>
        <v-list-item v-if="media.isMobile && canUpdateInvoices" @click="issueInvoice">
          <v-list-item-icon class="mr-1">
            <v-icon>mdi-plus</v-icon>
          </v-list-item-icon>

          <v-list-item-title> {{ $t('button.issue_invoice') }} </v-list-item-title>
        </v-list-item>

        <v-list-item v-if="media.isMobile && canUpdateInvoices" @click="issueRecurrenceInvoice">
          <v-list-item-icon class="mr-1">
            <v-icon>mdi-plus</v-icon>
          </v-list-item-icon>

          <v-list-item-title> {{ $t('button.issue_reccurence_invoice') }} </v-list-item-title>
        </v-list-item>

        <v-list-item @click="exportInvoice">
          <v-list-item-icon class="mr-1">
            <v-icon>mdi-file-download</v-icon>
          </v-list-item-icon>

          <v-list-item-title>
            {{ $t('button.export') }}
          </v-list-item-title>
        </v-list-item>

        <v-list-item v-if="!isRecurrence" @click="exportAccountingData">
          <v-list-item-icon class="mr-1">
            <v-icon>mdi-file-download</v-icon>
          </v-list-item-icon>

          <v-list-item-title>
            {{ $t('invoice.export_accounting_data') }}
          </v-list-item-title>
        </v-list-item>

        <file-input v-slot="{ on, attrs }" @input="importInvoices">
          <!-- 
              @click.prevent необходим для избежания двойного вызова окна загрузки input'а, 
              @click.stop не позволяет добиться этого поведения 
            -->
          <v-list-item v-if="canUpdateInvoices" v-bind="attrs" :disabled="isInProcess" v-on="on" @click.prevent>
            <v-list-item-icon class="mr-1">
              <v-icon>mdi-file-upload</v-icon>
            </v-list-item-icon>

            <v-list-item-title>
              {{ $t('button.import') }}
            </v-list-item-title>
          </v-list-item>
        </file-input>

        <v-list-item v-if="isUnpaid" @click="sendReminder">
          <v-list-item-icon class="mr-1">
            <v-icon>mdi-checkbox-multiple-marked</v-icon>
          </v-list-item-icon>

          <v-list-item-title> {{ $t('button.send_reminder') }} </v-list-item-title>
        </v-list-item>

        <v-list-item @click="openSettings">
          <v-list-item-icon class="mr-1">
            <v-icon>mdi-cogs</v-icon>
          </v-list-item-icon>

          <v-list-item-title> {{ $t('invoice.settings') }} </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-menu
      v-if="canUpdateInvoices && !media.isMobile && !isRecurrence"
      v-model="menu"
      bottom
      :offset-y="true"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <icon-button class="ml-3" v-bind="attrs" v-on="on">
          <v-icon color="primary">mdi-credit-card-outline</v-icon>
        </icon-button>
      </template>

      <v-list class="payment-type-list">
        <v-list-item-group v-model="paymentTypeSelect" color="primary">
          <v-list-item v-if="!isUnpaid">
            <v-list-item-content>
              <v-list-item-title>{{ $t('payment_type.unpaid') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-for="paymentTypeItem in paymentTypes"
            :key="paymentTypeItem.value"
            :value="paymentTypeItem.value"
          >
            <v-list-item-content>
              <v-list-item-title>{{ $t(paymentTypeItem.name) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-item>
          <v-text-field
            v-model="paymentTypeComment"
            maxlength="64"
            counter="64"
            :placeholder="$t('label.comment')"
            class="mb-3"
          ></v-text-field>
        </v-list-item>
        <div class="text-center mb-2">
          <v-btn color="primary" @click="savePaymentType">{{ $t('button.save') }}</v-btn>
        </div>
      </v-list>
    </v-menu>

    <icon-button v-if="isAll" class="ml-3" @click="openTabeSettings">
      <v-icon color="primary">mdi-cogs</v-icon>
    </icon-button>
  </div>
</template>

<script>
// Constants
import { IN_PROCESS } from '@/constants/importStatuses';
import { ALL, UNPAID, RECURRENCE } from '@/constants/entityStatuses';

// Components
import FileInput from '@/components/FileInput.vue';
import IconButton from '@/components/IconButton.vue';

export default {
  name: 'InvoicesMenu',

  components: { FileInput, IconButton },

  inject: ['media'],

  importStatuses: {
    IN_PROCESS,
  },

  props: {
    canUpdateInvoices: { type: Boolean, default: false },
    status: { type: String, required: true },
    importStatus: { type: String, default: '' },
    paymentTypes: { type: Array, required: true },
  },

  data: () => ({
    menu: false,
    paymentTypeSelect: '',
    paymentTypeComment: '',
  }),

  computed: {
    isAll() {
      return this.status === ALL;
    },
    isUnpaid() {
      return this.status === UNPAID;
    },
    isRecurrence() {
      return this.status === RECURRENCE;
    },
    unpaidButtonColor() {
      return this.isUnpaid ? 'accent' : 'primary';
    },
    isInProcess() {
      return this.importStatus === this.$options.importStatuses.IN_PROCESS;
    },
  },

  methods: {
    importInvoices(files) {
      this.$emit('import', files);
    },

    sendReminder() {
      this.$emit('send-reminder');
    },

    exportInvoice() {
      this.$emit('export');
    },

    exportAccountingData() {
      this.$emit('export-accounting-data');
    },

    issueInvoice() {
      this.$emit('issue-invoice');
    },

    issueRecurrenceInvoice() {
      this.$emit('issue-recurrence-invoice');
    },

    openSettings() {
      this.$emit('open-settings');
    },

    openTabeSettings() {
      this.$emit('open-table-settings');
    },

    savePaymentType() {
      this.menu = false;
      const { paymentTypeSelect } = this;
      const { paymentTypeComment } = this;
      this.paymentTypeComment = '';
      this.paymentTypeSelect = null;
      this.$emit('save-payment-type', paymentTypeSelect || null, paymentTypeComment);
    },
  },
};
</script>

<style lang="scss">
.payment-type-list {
  .v-input--selection-controls__input {
    position: absolute !important;
    opacity: 0 !important;
    overflow: hidden !important;
    width: 0 !important;
    height: 0 !important;
    pointer-events: none !important;
  }

  .v-input--selection-controls .v-input__slot,
  .v-input--selection-controls .v-radio,
  .v-input {
    width: 100%;
  }

  .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
    border-color: rgba(0, 0, 196, 0.15) !important;
  }
}

.payment-type-active {
  background: $--blue-color-40 !important;

  label {
    color: $--blue-color-10 !important;
  }
}
</style>
