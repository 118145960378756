<template>
  <div class="invoices-mobile-list">
    <template v-if="isEmployee">
      <template v-if="isRecurrence">
        <employee-recurrence-invoice
          v-for="item in items"
          :key="item.id"
          class="mb-2"
          :invoice-id="item.id"
          :recurrence-period="item.recurrencePeriod"
          :client-name="item.clientName"
          :project="item.projectName"
          :company-name="item.companyName"
          :unit="item.unitName"
          :room="item.roomName"
          :service="getServiceType(item)"
          :total-amount="item.totalAmount"
          :currency-symbol="item.currency?.symbol"
          :status="item.status"
          :is-loading="item.isLoading"
          :progress="item.progress"
          :file-name="item.fileName"
          :is-error="item.isError"
          :first-invoice="invoiceFormat(item.invoiceDateFirst)"
          :last-invoice="invoiceFormat(item.invoiceDateLast)"
          :access-to-rooms="accessToRooms"
          @detailed="downloadInvoice(item)"
          @close-overlay="closeOverlay(item)"
          @delete="deleteItem(item)"
          @edit-invoice="editInvoice(item)"
        />
      </template>

      <template v-else>
        <employee-invoice
          v-for="item in items"
          :key="item.id"
          :invoice-id="item.id"
          class="mb-2"
          :invoice="item"
          :payment-types="paymentTypes"
          :payment-type-id="item.paymentTypeId"
          :payment-comment="item.paymentComment"
          :period="
            $options.translateInvoiceDate(item.isOneTime, item.period, item.periodType !== 'day' && item.periodEnd)
          "
          :date="$options.translateDate(item.invoiceDate)"
          :client-name="item.clientName"
          :project="item.projectName"
          :building="item.buildingName"
          :company-name="item.companyName"
          :unit="item.unitName"
          :room="item.roomName"
          :due-date="$options.translateDate(item.dueDate)"
          :delayed-payment="item.delayedPayment"
          :service="getServiceType(item)"
          :total-amount="item.totalAmount"
          :currency-symbol="item.currency.symbol"
          :status="item.status"
          :payment-date="$options.translateDate(item.paymentDate)"
          :is-loading="item.isLoading"
          :progress="item.progress"
          :file-name="item.fileName"
          :is-error="item.isError"
          :access-to-rooms="accessToRooms"
          :colums-config="invoiceTableConfigsObject"
          :code-language="codeLanguage"
          @update-comment="$emit('update-comment', $event)"
          @detailed="downloadInvoice(item)"
          @close-overlay="closeOverlay(item)"
          @delete="deleteItem(item)"
          @save-payment-type="savePaymentType"
        />
      </template>
    </template>

    <template v-else>
      <client-invoice
        v-for="item in items"
        :key="item.id"
        class="mb-2"
        :date="$options.translateDate(item.invoiceDate)"
        :period="$options.translateInvoiceDate(item.isOneTime, item.period)"
        :unit="item.unitName"
        :service="getServiceType(item)"
        :company-name="item.companyName"
        :status="item.status"
        :total-amount="item.totalAmount"
        :currency-symbol="item.currency.symbol"
        :payment-date="$options.translateDate(item.paymentDate)"
        :payment-id="item.id"
        :is-loading="item.isLoading"
        :progress="item.progress"
        :file-name="item.fileName"
        :is-error="item.isError"
        @detailed="downloadInvoice(item)"
        @close-overlay="closeOverlay(item)"
      />
    </template>
  </div>
</template>

<script>
// Constants
import { RECURRENCE } from '@/constants/entityStatuses';

// Utils
import { translateDate, translateInvoiceDate } from '@/utils/dateFormatting';
import { getServiceType } from '@/utils/common';

// Components
import ClientInvoice from './ClientInvoice.vue';
import EmployeeInvoice from './EmployeeInvoice.vue';
import EmployeeRecurrenceInvoice from './EmployeeRecurrenceInvoice.vue';

export default {
  name: 'InvoicesMobileList',

  components: { ClientInvoice, EmployeeInvoice, EmployeeRecurrenceInvoice },

  props: {
    items: { type: Array, required: true },
    paymentTypes: { type: Array, required: true },
    isEmployee: { type: Boolean, default: false },
    status: { type: String, required: true },
    accessToRooms: { type: Boolean, default: false },
    invoiceTableConfigs: { type: Array, required: true },
    codeLanguage: { type: String, default: 'en' },
  },

  computed: {
    isRecurrence() {
      return this.status === RECURRENCE;
    },
    invoiceTableConfigsObject() {
      return this.invoiceTableConfigs.reduce((acc, item) => {
        const { value, ...rest } = item;
        acc[value] = rest;
        return acc;
      }, {});
    },
  },

  methods: {
    deleteItem(item) {
      this.$emit('delete', item);
    },

    downloadInvoice(item) {
      this.$emit('download-invoice', item);
    },

    closeOverlay(item) {
      this.$emit('close-overlay', item);
    },

    getServiceType(item) {
      return this.$t(item?.serviceType?.name) || item.service;
    },

    invoiceFormat(date) {
      return translateDate(date);
    },

    savePaymentType(paymentType, paymentTypeComment, invoiceId) {
      this.$emit('save-payment-type', paymentType, paymentTypeComment, invoiceId);
    },
  },

  translateDate,
  translateInvoiceDate,
  getServiceType,
};
</script>

<style lang="scss">
.invoices-mobile-list {
  &__service {
    color: $--primary-color !important;
  }

  &__unpaid {
    opacity: 0.9;
    color: $--black-color-0 !important;
  }
}
</style>
